<template>
  <div>
    <headers />
    <div class="content">
      <div class="jAPxxm">
        <img class="bg" :src="require('@/assets/image/h5/enterdigiland/bg.png')" alt="" />
        <div class="frPNiP">
          <div class="ksEuhS">
            <div class="cTJIxw">
              <p>基于真实能源和生产设备数据的工业品碳足迹平台，中小制造业企业数字化专家</p>
            </div>
            <div class="isHFlM">
              <p>千家中小型制造业企业数字化实施,大量工业数据协同，形成精准碳工业品足迹。<br/>
公司拥有完全自主研发的全套软硬件和SaaS服务平台，以人工智能为核心技术，对<br/>制造业中小企业进行快速数字化<br/>
转型。让企业植根与数字土壤之中茁壮成长，助力每个中小型制造业企业成长为参<br/>
天大树。同时通过多种AI和算法协同，应用区块链技术构筑起关键节点数据加密和<br/>确权，形成以真实数据为底层，产业链为依托的工业品碳足迹服务平台。</p>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <div class="page-content">
          <div class="intro-title">数壤成长</div>
          <div class="page-upList">
            <div class="page-up">
              <div class="page-icon">
                <img
                  :src="require('@/assets/image/h5/enterdigiland/icon1.png')"
                  alt=""
                  class="icon1"
                />
              </div>
              <div class="up-title">公司成立</div>
              <div class="up-year">2018</div>
              <div class="up-info">
                创始团队在苏州组建,立志为中小企业提供数字化转型服务.
              </div>
            </div>
            <div class="up-arrow">
              <img
                :src="require('@/assets/image/h5/enterdigiland/arrow1.png')"
                alt=""
                class="arrow"
              />
            </div>
            <div class="page-up">
              <div class="page-icon">
                <img
                  :src="require('@/assets/image/h5/enterdigiland/icon2.png')"
                  alt=""
                  class="icon2"
                />
              </div>
              <div class="up-title">架构搭建</div>
              <div class="up-year">2019</div>
              <div class="up-info1">
                基于能源管理,PLM和MES三种企业常用数字化转型工具进行基础框架搭建,同时完成教育实训产品研发.对海量中小型制造业企业充分调研,确定通用需求和产品方向.
              </div>
            </div>

            <div class="up-arrow">
              <img
                :src="require('@/assets/image/h5/enterdigiland/arrow1.png')"
                alt=""
                class="arrow"
              />
            </div>
            <div class="page-up">
              <div class="page-icon">
                <img
                  :src="require('@/assets/image/h5/enterdigiland/icon3.png')"
                  alt=""
                  class="icon3"
                />
              </div>
              <div class="up-title">推广实践</div>
              <div class="up-year">2020</div>
              <div class="up-info1">
                不断进行技术研发投入,对产品进行迭代优化,降低企业使用产品门槛.在马鞍山市刃模具行业,淄博铸造行业,潍坊大型装备行业,内蒙古有色金属行业,山西水泥行业等落地验证并得到良好反馈,逐渐形成产品和服务体系标准化.
              </div>
            </div>

            <div class="page-up up-top">
              <div class="page-icon">
                <img
                  :src="require('@/assets/image/h5/enterdigiland/icon4.png')"
                  alt=""
                  class="icon4"
                />
              </div>
              <div class="up-lg-title">立足夯实底蕴</div>
              <div class="up-year">2023</div>
              <div class="up-info">
                通过VR/AR等技术结合数字孪生，应用区块链技术构筑起关键节点数据加密和确权，全面打造工业元宇宙。
              </div>
            </div>
            <div class="up-top-arrow">
              <img
                :src="require('@/assets/image/h5/enterdigiland/arrow2.png')"
                alt=""
                class="arrow"
              />
            </div>
            <div class="page-up up-top">
              <div class="page-icon">
                <img
                  :src="require('@/assets/image/h5/enterdigiland/icon5.png')"
                  alt=""
                  class="icon5"
                />
              </div>
              <div class="up-title">全面展开</div>
              <div class="up-year">2022</div>
              <div class="up-info1">
                千家企业实施拓展,工业数字化全面展开,山东、江苏、浙江、安徽多点开花
              </div>
            </div>

            <div class="up-top-arrow">
              <img
                :src="require('@/assets/image/h5/enterdigiland/arrow2.png')"
                alt=""
                class="arrow"
              />
            </div>
            <div class="page-up up-top">
              <div class="page-icon">
                <img
                  :src="require('@/assets/image/h5/enterdigiland/icon6.png')"
                  alt=""
                  class="icon6"
                />
              </div>
              <div class="up-title">技术完善</div>
              <div class="up-year">2021</div>
              <div class="up-info1">
                完成基于IOT技术的多种核心设备数据采集硬件的研发并成功投入使用,同年完成数壤大数据管理平台的研发上线,为企业和行业提供基于人工智能和大数据的服务.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page1">
        <div class="page_title">数壤——制造业成为参天大树的数字土壤</div>
        <div class="page_headImg">
          <img :src="require('@/assets/image/h5/enterdigiland/content1.png')" alt="" />
        </div>
        <div class="page_card">
          <div class="card_title">工业元宇宙与数字孪生的场景应用实现</div>
          <div class="card_imgDiv">
            <img :src="require('@/assets/image/h5/enterdigiland/content2.png')" alt="" />
          </div>
        </div>

        <div class="page_card">
          <div class="card_title">丰富的大数据背景</div>
          <div class="card_imgDiv">
            <img :src="require('@/assets/image/h5/enterdigiland/content3.png')" alt="" />
          </div>
        </div>

        <div class="page_card">
          <div class="card_title">深耕的制造业开发</div>
          <div class="card_imgDiv">
            <img :src="require('@/assets/image/h5/enterdigiland/content4.png')" alt="" />
          </div>
        </div>

        <div class="page_card">
          <div class="card_title">资深的专家伙伴</div>
          <div class="card_imgDiv">
            <img :src="require('@/assets/image/h5/enterdigiland/content5.png')" alt="" />
          </div>
        </div>
      </div>

      <div class="page2">
        <div class="page-content">
          <div class="page_title">加入数壤，共同打造工业元宇宙</div>
          <div class="page_imgDiv">
            <img :src="require('@/assets/image/h5/enterdigiland/content6.png')" alt="" />
          </div>
          <div class="intro_join">
            <div>成为合伙人</div>
            <div>成为员工</div>
          </div>
        </div>
      </div>

      <div class="page3">
        <div class="intro_img">
          <img :src="require('@/assets/image/h5/enterdigiland/content7.png')" alt="" />
        </div>
      </div>
      <div class="page_info">
        汇聚天下英才，植根数字土壤，培养制造业大树，打造工业元宇宙
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from "@/components/H5/hend";
import footers from "@/components/H5/foot";
export default {
  components: {
    headers,
    footers,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
a {
  color: white;
}

.content {
  min-width: 320px;
  max-width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  font-size: 14px;
  line-height: 1.6;

  .jAPxxm {
    position: relative;
    width: 100%;
    height: 476px;
    margin-bottom: 30px;

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .frPNiP {
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      .ksEuhS {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .cTJIxw {
        font-size: 17px;
        // height: 24px;
        line-height: 24px;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        text-align: center;
        display: flex;
        flex-direction: column;
        width: 340px;
        margin-bottom: 20px;
      }

      .isHFlM {
        width: 313px;
        height: 140px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        opacity: 0.7;
      }
    }
  }
  .page {
    margin: auto;
    padding: 0 15px;
    .page-content {
      .intro-title {
        position: relative;
        margin-bottom: 20px;
        padding-left: 10px;
        height: 23px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.85);
        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          content: "";
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
        }
      }
      .page-upList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .page-up {
          width: 85px;
          height: 230px;
          border-radius: 5px;
          border: 1px solid #308971;
          .page-icon {
            width: 35px;
            height: 35px;
            border: 1px solid #308971;
            border-radius: 50%;
            text-align: center;
            margin: 13px 25px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon1 {
              width: 18px;
              height: 18px;
            }
            .icon2 {
              width: 17px;
              height: 16px;
            }
            .icon3 {
              width: 16px;
              height: 15px;
            }
            .icon4 {
              width: 18px;
              height: 18px;
            }
            .icon5 {
              width: 16px;
              height: 16px;
            }
            .icon6 {
              width: 17px;
              height: 16px;
            }
          }
          .up-title {
            text-align: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 17px;
          }
          .up-lg-title {
            text-align: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 17px;
          }
          .up-year {
            width: 55px;
            height: 20px;
            background: #308971;
            border-radius: 2px;
            font-size: 11px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            text-align: center;
            margin: 5px 15px;
          }
          .up-info {
            width: 56px;
            height: 44px;
            font-size: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 11px;
            margin: 0 15px;
          }
          .up-info1 {
            width: 75px;
            // height: 88px;
            font-size: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 11px;
            margin: 0 5px;
          }
        }
        .up-top {
          margin-top: 23px;
        }
        .up-arrow {
          margin: 105px 5px;
          .arrow {
            width: 35px;
            height: 7px;
          }
        }
        .up-top-arrow {
          margin: 130px 5px 0;
          .arrow {
            width: 35px;
            height: 7px;
          }
        }
      }
    }
  }
  .page1 {
    // width: 345px;
    // height: 1323px;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
    border-radius: 1px;
    margin: 30px 15px;
    padding-bottom: 20px;
    .page_title {
      height: 42px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 42px;
      padding-left: 15px;
      border-bottom: 1px solid #e9e9e9;
    }
    .page_headImg {
      margin: 14px 15px 15px;
      text-align: center;
      img {
        width: 315px;
        // width: 100%;
        height: 159px;
      }
    }
    .page_card {
      margin: 0 15px 10px;
      // width: 315px;
      // height: 261px;
      background: #ffffff;
      border-radius: 2px 2px 0px 0px;
      border: 1px solid #e9e9e9;
      .card_title {
        // width: 315px;
        // height: 30px;
        background: #fafafa;
        border-radius: 2px 2px 0px 0px;
        border-bottom: 1px solid #e9e9e9;
        padding: 10px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .card_imgDiv {
        margin: 10px;
        text-align: center;
        img {
          width: 295px;
          height: 210px;
        }
      }
    }
  }
  .page2 {
    margin: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .page-content {
      .page_title {
        position: relative;
        margin-bottom: 20px;
        padding-left: 10px;
        height: 23px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.85);
        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          content: "";
          width: 3px;
          height: 23px;
          background: #308971;
          border-radius: 2px;
        }
      }
      .page_imgDiv {
        width: 100%;
        img {
          width: 345px;
          height: 197px;
        }
      }
      .intro_join {
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 0px 0px 1px 1px;
        div {
          width: 172px;
          text-align: center;
          padding: 9px 0;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 17px;
          &:first-child {
            border-right: 1px solid #e9e9e9;
          }
        }
      }
    }
  }

  .page3 {
    margin: 20px 15px 0;

    .intro_img {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // text-align: center;
      img {
        width: 345px;
        height: 197px;
      }
    }
  }
  .page_info {
    margin-bottom: 30px;
    padding: 10px 0 9px;
    //   width: 319px;
    //   height: 15px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
